var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-row",
        { staticClass: "my-0 py-0 primary", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center py-2 my-0 pl-4",
              attrs: { cols: "12" }
            },
            [
              _c("v-skeleton-loader", {
                staticClass: "mr-8",
                attrs: { type: "image", "max-height": "25", width: "30" }
              }),
              _c("v-skeleton-loader", {
                staticClass: "mr-6",
                attrs: { type: "image", width: "200", "max-height": "35" }
              }),
              _c("v-skeleton-loader", {
                staticClass: "mr-8",
                attrs: { width: "400", type: "image", "max-height": "45" }
              }),
              _c("v-spacer"),
              _c("v-skeleton-loader", {
                staticClass: "mr-3",
                attrs: { width: "200", type: "image", "max-height": "35" }
              }),
              _c("v-skeleton-loader", {
                staticClass: "mr-7",
                attrs: { type: "avatar", "max-height": "50" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "fill-height mt-0" },
        [
          _c(
            "v-col",
            { staticClass: "mt-0 py-0 px-0 d-flex", attrs: { cols: "12" } },
            [
              _c(
                "div",
                {
                  staticClass: "fill-height primary pt-2",
                  staticStyle: { width: "268px" }
                },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: {
                      type: "image",
                      "max-height": "120",
                      "max-width": "120"
                    }
                  }),
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto mt-1",
                    attrs: {
                      type: "image",
                      "min-width": "120",
                      width: "160",
                      height: "20"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-7 ml-3" },
                    [
                      _vm._l(6, function(index) {
                        return [
                          _c(
                            "div",
                            { key: index, staticClass: "d-flex mx-6" },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "mr-5",
                                attrs: {
                                  type: "image",
                                  "max-height": "20",
                                  width: "60",
                                  "max-width": "20"
                                }
                              }),
                              _c("v-skeleton-loader", {
                                staticClass: "pt-1",
                                attrs: {
                                  type: "text",
                                  width: "140",
                                  height: "48"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }