<template>
    <div class="fill-height">
        <v-row no-gutters class="my-0 py-0 primary">
            <v-col cols="12" class="d-flex align-center py-2 my-0 pl-4">
                <v-skeleton-loader
                    class="mr-8"
                    type="image"
                    max-height="25"
                    width="30"
                ></v-skeleton-loader>

                <v-skeleton-loader
                    class="mr-6"
                    type="image"
                    width="200"
                    max-height="35"
                ></v-skeleton-loader>

                <v-skeleton-loader
                    class="mr-8"
                    width="400"
                    type="image"
                    max-height="45"
                ></v-skeleton-loader>

                <v-spacer></v-spacer>
                <v-skeleton-loader
                    class="mr-3"
                    width="200"
                    type="image"
                    max-height="35"
                ></v-skeleton-loader>
                <v-skeleton-loader
                    class="mr-7"
                    type="avatar"
                    max-height="50"
                ></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row class="fill-height mt-0">
            <v-col cols="12" class="mt-0 py-0 px-0 d-flex">
                <div class="fill-height primary pt-2" style="width: 268px">
                    <v-skeleton-loader
                        class="mx-auto"
                        type="image"
                        max-height="120"
                        max-width="120"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                        type="image"
                        min-width="120"
                        width="160"
                        height="20"
                        class="mx-auto mt-1"
                    ></v-skeleton-loader>
                    <div class="mt-7 ml-3">
                        <template v-for="index in 6">
                            <div :key="index" class="d-flex mx-6">
                                <v-skeleton-loader
                                    type="image"
                                    max-height="20"
                                    width="60"
                                    max-width="20"
                                    class="mr-5"
                                ></v-skeleton-loader>
                                <v-skeleton-loader
                                    class="pt-1"
                                    type="text"
                                    width="140"
                                    height="48"
                                ></v-skeleton-loader>
                            </div>
                        </template>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>